import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { HomepageComponent } from './homepage/homepage.component';
import { ProjectsComponent } from './projects/projects.component';
import { ClientsComponent } from './clients/clients.component';
import { ResidentialComponent } from './residential/residential.component';
import { CommercialComponent } from './commercial/commercial.component';
import { HospitalityComponent } from './hospitality/hospitality.component';
import { InstitutionsComponent } from './institutions/institutions.component';
import { CorporateComponent } from './corporate/corporate.component';
import { SocietiesComponent } from './societies/societies.component';
import { BuildersComponent } from './builders/builders.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomepageComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  {
     path: 'projects', component: ProjectsComponent},
  { path: 'clients', component: ClientsComponent },
   { path: 'projects/residential', component: ResidentialComponent },
   { path: 'projects/commercial', component: CommercialComponent },
   { path: 'projects/hospitality', component: HospitalityComponent },
   { path: 'projects/institutions', component: InstitutionsComponent },
  { path: 'projects/corporate', component: CorporateComponent },
  { path: 'projects/societies', component: SocietiesComponent },
  { path: 'projects/builders', component: BuildersComponent },

  { path: '**',  component: PageNotFoundComponent }

];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // Add options right here
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
