import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { OwlModule } from 'ngx-owl-carousel';  
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {OverlayModule} from '@angular/cdk/overlay';
import {ReactiveFormsModule} from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NgHttpLoaderModule } from 'ng-http-loader';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { HomepageComponent } from './homepage/homepage.component';
import { ProjectsComponent } from './projects/projects.component';
import { ContactComponent } from './contact/contact.component';
import { ClientsComponent } from './clients/clients.component';
import { ResidentialComponent } from './residential/residential.component';
import { CommercialComponent } from './commercial/commercial.component';
import { HospitalityComponent } from './hospitality/hospitality.component';
import { InstitutionsComponent } from './institutions/institutions.component';
import { CorporateComponent } from './corporate/corporate.component';
import { SocietiesComponent } from './societies/societies.component';
import { BuildersComponent } from './builders/builders.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    HomepageComponent,
    ProjectsComponent,
    ContactComponent,
    ClientsComponent,
    ResidentialComponent,
    CommercialComponent,
    HospitalityComponent,
    InstitutionsComponent,
    CorporateComponent,
    SocietiesComponent,
    BuildersComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,OwlModule,BrowserAnimationsModule, GalleryModule,
    LightboxModule,OverlayModule,ReactiveFormsModule,FormsModule, NgHttpLoaderModule.forRoot(),
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule  { }
