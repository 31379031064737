import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-residential',
  templateUrl: './residential.component.html',
  styleUrls: ['./residential.component.css']
})
export class ResidentialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
