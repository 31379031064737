import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    (<any>$('.list')).isotope({
      // options...
      itemSelector: '.list__item',
    layoutMode: 'masonry',
    masonry: {
      gutter: 0
    }
    });

    $('.js-filter').on( 'click', 'button', function() {
      var filterValue = $(this).attr('data-filter');
      (<any>$('.list')).isotope({ filter: filterValue });
      $( '.js-filter button' ).removeClass( 'is-active' );
      $( this ).addClass( 'is-active' );
    });
  }
  SlideOptions = { items: 2, dots: false, nav: true, autoplay:true, loop:true,autoplayTimeout:2750,
    responsive:{
      0:{
          items:1
      },
      600:{
          items:1
      },
      1000:{
          items:2
      }
  }};  
  CarouselOptions = { items: 2, dots: false, nav: true,autoplay:true, loop:true,autoplayTimeout:2750};

  
}
