import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder ,Validators} from '@angular/forms';
import { ContactxService } from './contactx.service';
import { Contactx } from './contactx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements AfterViewInit  {
  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  map: google.maps.Map;

  lat = 18.5084974;
  lng = 73.8792264;

  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 17
   };

  marker = new google.maps.Marker({
    position: this.coordinates,
    map: this.map,
  });

  ngAfterViewInit() {
    this.mapInitializer();
  }

  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement, 
    this.mapOptions);
    this.marker.setMap(this.map);
  }
  
  constructor(private mybuilder:FormBuilder , private cmspageService: ContactxService,private router: Router) { }

    // get fname(){ return this.contactform.get('fname')} 
    
    // get email_id(){ return this.contactform.get('email_id')} 
    // get phone(){ return this.contactform.get('phone')} 
    // get message(){ return this.contactform.get('message')} 
    
  

    // contactform = this.mybuilder.group({
    //  fname:['',[Validators.required,Validators.minLength(3),Validators.maxLength(20)]],
    //  email_id: ['',[Validators.required,Validators.email,Validators.pattern]],
    //  phone:['',[Validators.required,Validators.pattern]],
    //  message:['',[Validators.required,Validators.minLength(5),Validators.maxLength(50)]]

    // });
    // contactform = new FormGroup({
    //   fname:new FormControl(''),
    //   email_id: new FormControl(''),
    //   phone:new FormControl(''),
    //   message:new FormControl('')
    // })
    model = new Contactx();
    submitted = false;
    error: {};
    
    onSubmit() {
      this.submitted = true;
      return this.cmspageService.contactform(this.model).subscribe(
        data => this.model = data,
        error => this.error = error,

      );

    }
  
    
    gotoHome() {
      this.router.navigate(['/']);
    }
  
}
